<template>
  <div class="fill-height" data-cy="documents-public-show-page">
    <DocumentsPublicShow :tokenId="tokenId"></DocumentsPublicShow>
  </div>
</template>

<script>
import DocumentsPublicShow from "../components/DocumentsPublicShow.vue";

export default {
  name: "DocumentsPublicShowPage",
  components: {
    DocumentsPublicShow,
  },
  data: () => ({}),
  created() {},
  computed: {
    tokenId() {
      let tokenId = "";
      if (this.$route.params.token) {
        tokenId = this.$route.params.token.toString();
      }
      return tokenId;
    },
  },
};
</script>
